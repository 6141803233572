// src/MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useLocalState } from '../util/useLocalStorage';

// Fix Leaflet's default icon issue in React-Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const HeatmapLayer = () => {
  const map = useMap();

  useEffect(() => {
    const heat = L.heatLayer([
        [48.2827, -123.1207, 0.9],  // Vancouver
        [48.2857, -123.1307, 0.9],
        [48.2727, -123.1207, 0.9],
        [48.2907, -123.1107, 0.9],
        [48.2757, -123.1157, 0.9],
        [48.2957, -123.1057, 0.9],
        [48.2657, -123.1257, 0.9],
        [48.2857, -123.1357, 1.0],
        // Additional points 
        [48.2927, -123.1217, 0.6],
        [48.2797, -123.1107, 0.4],
        [48.2907, -123.1227, 0.5],
        [48.2827, -123.1177, 0.9]
    ], { radius: 100 }).addTo(map);

    return () => {
      map.removeLayer(heat);
    };
  }, [map]);

  return null;
};

const MapComponent = () => {
    const[jwt, setJwt]=useLocalState("", "jwt");
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markerData, setMarkerData] = useState(null);

  useEffect(() => {
    // Fetch markers from API
    const fetchMarkers = async () => {
      try {
        const response = await fetch('/api/hydrophones', {
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${jwt}`
            }, 
            method:"GET"
        });
        const data = await response.json();
        setMarkers(data);
        console.log("This is data" )
        console.dir(data)
      } catch (error) {
        console.error('Error fetching markers:', error);
      }
    };

    fetchMarkers();
  }, []);

  const handleMarkerClick = async (marker) => {
    setSelectedMarker(marker);
    // Fetch additional data for the clicked marker
    try {
        const response = await fetch(`/api/hydrophones/${marker.id}`, {
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${jwt}`
            }, 
            method:"GET"
        });
      const data = await response.json();
      setMarkerData(data);
    } catch (error) {
      console.error('Error fetching marker data:', error);
    }
  };

  return (
    <MapContainer center={[48.3295, -123.1407]} zoom={10} style={{ height: "100vh", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        noWrap={true} // Prevent the map from wrapping
      />

      {/* Render markers */}
      {markers.map((marker, index) => (
        <Marker 
          key={index} 
          position={[marker.latitude, marker.longitude]}
          eventHandlers={{
            click: () => handleMarkerClick(marker),
          }}
        >
          <Popup>
            {marker.name} <br /> {marker.description}
          </Popup>
        </Marker>
      ))}

      <HeatmapLayer />

      {/* component to display marker data */}
      {selectedMarker && markerData && (
        <div className="marker-info-popup">
          <h2>{markerData.name}</h2>
          <p>{markerData.details}</p>
        </div>
      )}
    </MapContainer>
  );
};

export default MapComponent;
